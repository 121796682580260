import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SDKData from "../content/sdk/index.json"
import Sdk from "../components/misc/sdk"
import HeroSubpage from "../components/heroes/hero-subpage"

const SDKPage = ({ intl }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={SDKData[intl.locale].title}
             description={SDKData[intl.locale].text}
        />
        <HeroSubpage
            theme={`dark`}
            content={SDKData[intl.locale]}
        />
        <Sdk content={SDKData[intl.locale]} />
    </Layout>
)

export default injectIntl(SDKPage)
