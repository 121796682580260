import React from 'react';
import { Container, Row, Col } from "react-bootstrap"
import {injectIntl, FormattedMessage} from "gatsby-plugin-react-intl"
import ImageFluid from "./image-fluid";
import { getSDKVersions } from "../../request";

class SDKLibraries extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            tab: "Stable",
            versions: []
        };
    }

    componentDidMount() {

        getSDKVersions().then((response) => {
            if (response && response.data && response.data.versions && response.data.versions.length > 0) {
                this.setState({
                    versions: response.data.versions
                });
            }
        });

    }

    fetchVersion(name){
        let output = null;
        this.state.versions.forEach((v) => {
            if (v.name === name) {
                output = v.version;
            }
        });
        return output;
    }

    render() {
        return (

            <div className={`sdk-libraries`}>
                <Container>
                    <div className={`latest`}>
                        <div className={`title`}>{this.props.content.latest.title}</div>
                        <div className={`text`} dangerouslySetInnerHTML={{__html: this.props.content.latest.text}}></div>
                        {/*<div className={`tabs`}>*/}
                        {/*    {this.props.content.latest.tabs.map((tab, i) => {*/}
                        {/*        return (*/}
                        {/*          <div key={i} className={this.state.tab === tab ? "tab active" : "tab"}>*/}
                        {/*              <a href={"/"} onClick={((e) => {*/}
                        {/*                  e.preventDefault();*/}
                        {/*                  this.setState({*/}
                        {/*                      tab: tab*/}
                        {/*                  });*/}
                        {/*              })}>{tab}</a>*/}
                        {/*          </div>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</div>*/}
                        <div className={`libraries`}>
                            <Row>
                                {(() => {
                                    if (this.state.tab === "Stable") {
                                        return this.props.content.latest.sdk.native.map((library, i) => {
                                            return (
                                              <Col key={i} lg={4}>
                                                  <div className={`library`}>
                                                      <div className={`image`}>
                                                          <ImageFluid alt={``} filename={library.image}/>
                                                      </div>
                                                      {this.fetchVersion(library.repo) ? <div className={`version`}><FormattedMessage id="shared.latestVersion" /> <span>{this.fetchVersion(library.repo)}</span></div> : <div className={`version`}><FormattedMessage id="shared.comingSoon" /></div>}
                                                      {library.buttons.map((btn, j) => {
                                                          return (
                                                            <a key={j} className={btn.url ? `button` : `button disabled`} href={btn.url}>{btn.label}</a>
                                                          )
                                                      })}
                                                  </div>
                                              </Col>
                                            )
                                        })
                                    }
                                    // if (this.state.tab === "Pre-Releases") {
                                    //     return this.props.content.latest.prereleases.native.map((library, i) => {
                                    //         return (
                                    //           <Col key={i} lg={4}>
                                    //               <div className={`library`}>
                                    //                   <div className={`image`}>
                                    //                       <ImageFluid alt={``} filename={library.image}/>
                                    //                   </div>
                                    //                   {this.fetchVersion(library.repo) ? <div className={`version`}><FormattedMessage id="shared.latestVersion" /> <span>{this.fetchVersion(library.repo)}</span></div> : <div className={`version`}><FormattedMessage id="shared.comingSoon" /></div>}
                                    //                   {library.buttons.map((btn, j) => {
                                    //                       return (
                                    //                         <a key={j} className={btn.url ? `button` : `button disabled`} href={btn.url}>{btn.label}</a>
                                    //                       )
                                    //                   })}
                                    //               </div>
                                    //           </Col>
                                    //         )
                                    //     })
                                    // }
                                })()}
                            </Row>
                            <Row className="justify-content-lg-center">
                                {(() => {
                                    if (this.state.tab === "Stable") {
                                        return this.props.content.latest.sdk.plugins.map((library, i) => {
                                            return (
                                              <Col key={i} lg={2}>
                                                  <div className={`library`}>
                                                      <div className={`image`}>
                                                          <ImageFluid alt={``} filename={library.image}/>
                                                      </div>
                                                      {this.fetchVersion(library.repo) ? <div className={`version`}><FormattedMessage id="shared.latestVersion" /> <span>{this.fetchVersion(library.repo)}</span></div> : <div className={`version`}><FormattedMessage id="shared.comingSoon" /></div>}
                                                      {library.buttons.map((btn, j) => {
                                                          return (
                                                            <a key={j} className={btn.url ? `button` : `button disabled`} href={btn.url}>{btn.label}</a>
                                                          )
                                                      })}
                                                  </div>
                                              </Col>
                                            )
                                        })
                                    }
                                    // if (this.state.tab === "Pre-Releases") {
                                    //     return this.props.content.latest.prereleases.plugins.map((library, i) => {
                                    //         return (
                                    //           <Col key={i} lg={3}>
                                    //               <div className={`library`}>
                                    //                   <div className={`image`}>
                                    //                       <ImageFluid alt={``} filename={library.image}/>
                                    //                   </div>
                                    //                   {this.fetchVersion(library.repo) ? <div className={`version`}><FormattedMessage id="shared.latestVersion" /> <span>{this.fetchVersion(library.repo)}</span></div> : <div className={`version`}><FormattedMessage id="shared.comingSoon" /></div>}
                                    //                   {library.buttons.map((btn, j) => {
                                    //                       return (
                                    //                         <a key={j} className={btn.url ? `button` : `button disabled`} href={btn.url}>{btn.label}</a>
                                    //                       )
                                    //                   })}
                                    //               </div>
                                    //           </Col>
                                    //         )
                                    //     })
                                    // }
                                })()}
                            </Row>
                        </div>
                    </div>
                    <div className={`others`}>
                        <div className={`title`}>{this.props.content.others.title}</div>
                        <div className={`text`} dangerouslySetInnerHTML={{__html: this.props.content.others.text}}></div>

                        <div className={`libraries`}>
                            <div className="generation-title">v2.x</div>
                            {this.props.content.others.sdk.v2.map((library, i) => {
                                return (
                                  <div key={i} className={`library`}>
                                      <div className={`image`}>
                                          <ImageFluid alt={``} filename={library.image}/>
                                      </div>
                                      <div className={`links`}>
                                          {library.buttons.map((btn, j) => {
                                              return (
                                                <a key={j} className={btn.url ? `button` : `button disabled`} href={btn.url}>{btn.label}</a>
                                              )
                                          })}
                                      </div>
                                  </div>
                                )
                            })}
                        </div>

                        <div className={`libraries`}>
                            <div className="generation-title">v1.x</div>
                            {this.props.content.others.sdk.v1.map((library, i) => {
                                return (
                                  <div key={i} className={`library`}>
                                      <div className={`image`}>
                                          <ImageFluid alt={``} filename={library.image}/>
                                      </div>
                                      <div className={`links`}>
                                          {library.buttons.map((btn, j) => {
                                              return (
                                                <a key={j} className={btn.url ? `button` : `button disabled`} href={btn.url}>{btn.label}</a>
                                              )
                                          })}
                                      </div>
                                  </div>
                                )
                            })}
                        </div>
                    </div>
                </Container>
            </div>

        )
    }
}

export default injectIntl(SDKLibraries)
